/* eslint-disable */
import React, {
  useRef,
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useMemo,
} from 'react';
import * as yup from 'yup';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import { useGeneralInfo } from 'core-modules/merchant-onboarding/hooks';
import Form, { ErrorLabel } from 'partial/form/Form';
import ImagePreview from 'core-modules/merchant-onboarding/components/ImagePreview';
import RequirementItem from 'core-modules/merchant-onboarding/components/RequirementItem';
import BusinessTypeOptions from 'core-modules/merchant-onboarding/components/BusinessTypeOptions';
import AttachmentGuidelines from 'core-modules/merchant-onboarding/components/AttachmentGuidelines';
import { useFormik } from 'formik';
import { useDatasetOptions } from 'core-modules/dataset/hooks';
import { MAP_BUSINESS_TYPE_DATA_SET_CODE } from '../constants';

const transformFormValues = (form) => {
  return {
    business_type: form?.business_type,
    requirements: Object.keys(form?.attachments || {}).map((key) => ({
      label: key,
      value: form?.attachments?.[key],
    })),
  };
};

export const AttachmentsForm = forwardRef(
  ({ readOnly, onSubmit, onSuccess }, ref) => {
    const [validateOnChange, setValidateOnChange] = useState(false);
    const requiredAttachmentFieldsRef = useRef();

    const [, generalInfo] = useGeneralInfo();

    const {
      errors,
      values,
      handleSubmit,
      setFieldError,
      setFieldValue,
      validateForm,
    } = useFormik({
      validationSchema: yup.object().shape({
        business_type: yup.string().required('Required'),
        attachments: yup.object().shape(
          requiredAttachmentFieldsRef?.current?.reduce(
            (acc, cur) => ({
              ...acc,
              [cur?.label]: yup.string().required(`${cur?.label} is required`),
            }),
            {}
          ) || {}
        ),
      }),
      validateOnChange,
      initialValues: {
        business_type: generalInfo?.business_type,
        attachments: {
          ...generalInfo?.requirements?.reduce(
            (acc, cur) => ({
              ...acc,
              [cur?.label]: cur?.value,
            }),
            {}
          ),
        },
      },
      onSubmit: async (form) => {
        onSubmit(transformFormValues(form), onSuccess, (err) => {
          each(err?.response?.errors, (v, k) => {
            setFieldError(k, v);
          });
        });
      },
    });

    const [isLoadingAttachmentFields, requiredAttachmentFieldsUnordered] =
      useDatasetOptions(
        MAP_BUSINESS_TYPE_DATA_SET_CODE?.[values?.business_type] || '',
        true
      );

    const requiredAttachmentFields = useMemo(
      () =>
        requiredAttachmentFieldsUnordered?.sort((a, b) => {
          if (a?.label > b?.label) return 1;
          if (b?.label > a?.label) return -1;
          return 0;
        }),
      [requiredAttachmentFieldsUnordered]
    );

    useEffect(() => {
      requiredAttachmentFieldsRef.current = requiredAttachmentFields;
      if (validateOnChange) {
        validateForm();
      }
    }, [requiredAttachmentFields, validateOnChange]);

    const [selected, setSelected] = React.useState('');

    const selectedValues = React.useMemo(
      () =>
        (values?.attachments?.[selected] || '')
          .split(',')
          .filter((v) => !isEmpty(v)),
      [selected, values, requiredAttachmentFields]
    );

    useEffect(() => {
      setSelected(requiredAttachmentFields?.[0]?.label);
    }, [requiredAttachmentFields, setSelected]);

    useImperativeHandle(
      ref,
      () => ({
        submit: handleSubmit,
        setValidateOnChange,
      }),
      [handleSubmit, setValidateOnChange]
    );

    useEffect(() => {
      setFieldValue('business_type', 'GOVERNMENT'); // SET GOVERNMENT as default
    }, []);

    return (
      <Form className="space-y-4" error={errors} onSubmit={() => {}}>
        {/* <div className="title-h2">Choose your type of business</div>
        <div className="space-y-2">
          <BusinessTypeOptions
            name="business_type"
            onChange={(cb) =>
              setFieldValue('business_type', cb()?.business_type)
            }
            value={values?.business_type || ''}
            readOnly={readOnly}
          />
          <ErrorLabel name="business_type" />
        </div> */}
        <div className="title-h2">Upload Document/s</div>
        <AttachmentGuidelines industry={generalInfo?.industry} />
        <div className="grid grid-cols-1 md:grid-cols-12 rounded-lg overflow-hidden bg-white border">
          <div className="col-span-7 p-2 min-h-[300px]">
            <ImagePreview
              // key={form?.[selected?.value] || ''}
              name={`attachments.${selected}`}
              onChange={(cb) => {
                setFieldValue(
                  `attachments.${selected}`,
                  cb()?.[`attachments.${selected}`]
                );
              }}
              values={selectedValues}
              label={selected?.label || ''}
              readOnly={readOnly}
            />
          </div>
          <div className="col-span-5 divide-y divide-gray-200 border-l bg-gray-100">
            <div className="text-gray-600 bg-white p-3 font-semibold">
              Upload document/s below:
            </div>
            {requiredAttachmentFields.map((field) => (
              <RequirementItem
                key={field?.label}
                name={`attachments.${field?.label}`}
                label={field?.label}
                onChange={(cb) =>
                  setFieldValue(
                    `attachments[${field?.label}]`,
                    cb()?.[`attachments.${field?.label}`]
                  )
                }
                value={values?.attachments?.[field?.label] || ''}
                isSelected={selected === field?.label}
                onSelect={() => setSelected(field?.label)}
                readOnly={readOnly}
              />
            ))}
            {/* {REQUIREMENTS.map((item) => (
            <RequirementItem
              key={item?.value}
              name={item?.value}
              label={item?.label}
              onChange={(cb) => setFieldValue(item?.value, cb()?.[item?.value])}
              value={values?.[item?.value] || ''}
              isSelected={selected === item}
              onSelect={() => setSelected(item)}
              readOnly={readOnly}
            />
          ))} */}
          </div>
        </div>
        <div className="space-y-1">
          {Object.keys(errors?.attachments || {})?.map((errorKey) => (
            <ErrorLabel key={errorKey} name={`attachments[${errorKey}]`} />
          ))}
        </div>
      </Form>
    );
  }
);

AttachmentsForm.defaultProps = {
  readOnly: false,
};

AttachmentsForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
};

export default AttachmentsForm;
